@import "vars";
.CourseSideList{
  width: 260px;
  flex-shrink: 0;
  box-shadow: 10px 0 10px 0 rgba(54,111,171,0.06);
  overflow-y: scroll;
  background: #f8f8f8;
  height: calc(100vh - 92px);
  z-index: 10;
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  &::-webkit-scrollbar {
    width: 0 !important
  }
  .CourseListItem{
    padding: 30px 20px;
    cursor: pointer;
    transition: @SlowCubicBezier;
    &:hover{
      background: white;
    }
  }
  .CourseListItemSelected{
    background: white;
  }
  .GoBack{
    display: none;

  }
}
.CourseListDetailMode{
  width: 100px;
  background: white;
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  position: relative;
  .CourseListItem{
    display: none;
  }
  .CourseListItemSelected{
    height: 100%;
    display: flex;
    justify-content: center;
    h3{
      width: 30px;
      text-overflow: unset;
      white-space: unset;
      word-wrap: break-word;
      writing-mode: vertical-rl;
      margin: 0 10px;
      letter-spacing: 5px;
    }
    .ant-typography{
      width: 24px;
    }
  }
  .GoBack{
    display: flex;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    a{
      width: 16px;
      font-size: 16px;
    }
  }

}
.CourseDetailPanel{
  background: white;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 92px);
  width: 100%;
  box-shadow: 10px 0 10px 0 rgba(54,111,171,.06);
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  .LockTop{
    position: absolute;
    left: 0;
    top:0;
    right: 0;
    height: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    h1{
      font-size: 48px;
      margin-bottom: 15px;
    }
    small{
      font-size: 24px;
    }
    .LockImg{
      height: 160px;
      width: 160px;
      position: absolute;
      bottom: -80px;
      transition: All 0.4s ease-in-out;
      z-index: 10;
    }
    .LockImgOpened{
      transform: rotate(90deg);
    }
    .LockTopBar{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #FFFCF8;
      border-top: 1px solid #F0EAE6;
      border-bottom: 1px solid #F0EAE6;
      height: 32px;
    }
  }
  .LockTopLeave{
    top:calc(0px - 50% - 80px);
  }
  .LockBottom{
    position: absolute;
    left: 0;
    bottom:0;
    right: 0;
    height: 50%;
    background: white;
    transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    z-index: 3;
    .LockBottomBar{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background:  #FFF8EF;
      border-top: 1px solid #F5E9E0;
      border-bottom: 1px solid #F5E9E0;
      height: 32px;
    }
    .LockBottomActiveArea{
      width: 400px;
      margin: 120px auto;
      text-align: center;
    }

  }
  .LockBottomLeave{
    bottom:calc(0px - 50%);
  }
  .LessonListHead{
    position: absolute;
    height: 86px;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 35px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.03);
    font-size: 18px;
    background: white;
    z-index: 3;
    transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    strong{
      transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    }
    span{
      transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    }
  }
  .LessonList{
    padding-top: 86px;
    background: white;
    overflow: scroll;
    height: calc(100vh - 105px);
    padding-bottom: 100px;
    transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0!important;
    }
    .LessonListItem{
      .LessonListItemHead{
        height: 120px;
        padding: 0 35px;
        display: flex;
        border-bottom: 1px solid #F5F5F5;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-size: 24px;
        transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
        .LessonListItemHeadTitle{
          strong {
            font-size: 24px;
            transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          }

          span {
            display: inline;
            font-size: 24px;
            transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          }
        }


        .LessonListItemArrow {
          color: #BFBFBF;
          transition: All 0.4s cubic-bezier(0.60, 0.00, 0.20, 1.00);
        }

        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
        }
      }
      .LessonListItemContent{
        background: #F5F5F7;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 35px;
        //justify-content: space-between;
        position: relative;
        font-size: 16px;
        .DetailItem {
          height: 55px;
          flex-basis: 340px;
          padding: 10px 15px;
          background: white;
          margin: 5px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03);
          cursor: pointer;
          transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          position: relative;

          &:hover {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
          }

          .DetailItemIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 36px;
            margin-right: 15px;

            img {
              height: 24px;
            }

            border-radius: 4px;
            transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          }

          .DetailItemIconVideo {
            background: rgba(255, 120, 120, 0.10);
            border: 1px solid #FF7878;
            flex-shrink: 0;
          }

          .DetailItemIconArticle {
            background: rgba(245, 166, 35, 0.10);
            border: 1px solid #F5A623;
            flex-shrink: 0;
          }

          .DetailItemIconExample {
            background: rgba(0, 123, 255, 0.10);
            border: 1px solid #007BFF;
            flex-shrink: 0;
          }

          .DetailItemIconQuestion {
            background: rgba(123, 198, 0, 0.10);
            border: 1px solid #7BC600;
            flex-shrink: 0;
          }

          .DetailSolveIcon {
            position: absolute;
            right: 15px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .LessonListItemOpen{
      .LessonListHead{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
      }
      .LessonListItemArrow{
        transform: rotate(180deg);
      }
    }

  }
}
.CourseDetailPanelDetailMode{
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  width: 300px;
  flex-shrink: 0;
  .LessonListHead{
    width: 300px;
  }
  .LessonList{
    width: 300px;
    .LessonListItem{
      .LessonListItemHead{
        .LessonListItemHeadTitle{
          strong{
            margin-right: 0!important;
            transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          }
          span{
            display: block;
            font-size: 18px;
            color: #c0c7d4;
            transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
          }
        }
        .LessonListItemPercent{
          display: none!important;
        }
      }
      .LessonListItemContent{
        padding: 5px 10px;
        .DetailItem{
          width: 190px;
        }
      }
    }
  }
}
.CourseDetailPanelBlur{
  .LessonListItemContent{
    animation: blur-ani 1s;
    transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  }
}


.CourseItemDetailPanel{
  position: absolute;
  width: calc(100vw - 515px - 20px);
  left: calc(100vw  + 30px);
  height: calc(100vh - 92px);
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important
  }
}
.CourseItemDetailPanelDetailMode{
  left: 415px;
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
}
