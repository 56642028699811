@import "./Theme";
.TabLayout{
  width: 100vw;
  height: 100vh;
  display: flex;
  background: @tab-layout-background-color;
  .TabLayoutMain{
    flex: 1;
    height: 100vh;
    .TabLayoutContent{
      overflow-y: auto;
      height: calc(100vh - 94px);
    }
  }
}

