@import "./Theme";

.TabLayoutHeader{
  background: white;
  padding-left: 10px;
  border-bottom:2px solid fadeout(darken(@tab-layout-primary,35),95);
  .TabLayoutHeaderTabs{
    flex: 1;
    display: flex;
    height: 40px;
    align-items: flex-end;
    padding-left: 3px;
    margin-bottom: -2px;
    user-select: none;
    .AppTabsPlus{
      flex-shrink: 0;
      height: 35px;
      color: #888;
      cursor: pointer;
      margin-left: 5px;
      font-size: 18px;
      opacity: 0.6;
      &:hover{
        opacity: 1;
      }
    }
    .AppTab{
      display: flex;
      box-shadow: 0 0 10px 2px fadeout(darken(@tab-layout-primary,15),90%);
      width: 140px;
      flex-shrink: 1;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      height: 35px;
      justify-content: space-between;
      padding: 0 10px;
      margin: 0 3px;
      cursor: pointer;
      background: #e6ecee;
      border-radius: 4px 4px 0 0;
      .AppTabClose{
        opacity: 0.7;
        margin-top: 1px;
        font-size: 12px;
        &:hover{
          color: #cf1322;
        }
      }
    }
    .AppTabActive{
      background: white;
      font-weight: bold;
    }
  }
}
