@import '~antd/dist/antd.less';
@import "markdownhere";
@import "smallMarkdown";
@import "~katex/dist/katex.min.css";
@import "./vars";
@import "./course";
.drag-able {
  user-select: none;
  cursor: pointer;

  .anticon-unordered-list {
    cursor: move;
  }

  &:hover {
    box-shadow: 0 6px 6px 0 rgba(7, 5, 11, 0.06);
    transform: translateY(-6px);
    transition: @SlowCubicBezier;
  }

  &:active {
    transition: @CubicBezier;
    box-shadow: 0 0 6px 0 rgba(7, 5, 11, 0.06);
  }
}

.ExampleInput {
  color: #5e6d82;
  width: 100%;
  border-radius: 2px;
  border: 2px solid #e8f3ff;
  margin-bottom: 10px;

  .ExampleInputHead {
    padding: 4px 11px;
    background: #edf5ff;
    border-bottom: 2px solid #e8f3ff;
    font-weight: bold;
  }

  textarea {
    background: #f5faff;
    border: none;
    margin-bottom: 0 !important;
    color: #5e6d82;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

    &:disabled {
      background: #f5faff;
      color: #5e6d82;
      cursor: text;
    }
  }
}

#upload-button {
  cursor: pointer;

  .file {
    position: relative;
    width: 100%;
    line-height: 22px;
    cursor: pointer;

    input {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}


.question-example {
  font-size: 14px;
  color: #5e6d82;
  border: none;
  margin: 20px 0;

  .example-row:nth-child(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .example-row-head {
      border-top: solid 2px #E8F3FF;
      border-top-left-radius: 4px;
    }

    .example-row-content {
      border-top: solid 2px #E8F3FF;
      border-top-right-radius: 4px;
    }
  }

  .example-row:nth-last-child(1) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .example-row-head {
      border-bottom-left-radius: 4px;
    }

    .example-row-content {
      border-bottom-right-radius: 4px;
    }
  }

  .example-row {
    display: flex;
    justify-content: space-between;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    background: #F5FAFF;

    .example-row-head {
      background: #EDF5FF;
      width: 100px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 5px 10px;
      font-weight: bold;
      border-bottom: solid 2px #E8F3FF;
      border-left: solid 2px #E8F3FF;

    }

    .example-row-content {
      width: 100%;
      position: relative;
      border-bottom: solid 2px #E8F3FF;
      border-right: solid 2px #E8F3FF;

      a {
        position: absolute;
        right: 20px;
        bottom: 10px;
      }

      textarea:disabled {
        background: #F5FAFF !important;
        border: 0;
        color: #5e6d82;
        padding: 10px 20px;
        font-family: Monaco, Consolas, "Courier New", monospace;
        overflow: hidden;
      }
    }
  }
}

.massa-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    td:nth-child(1), th:nth-child(1) {
      padding-left: 20px;
    }

    td:nth-last-child(1), th:nth-last-child(1) {
      padding-right: 20px;
    }
  }

  tr:nth-child(even) {
    background: #FAFAFA;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #444444;
    font-size: 14px;
  }

  th {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #8492a6;
    text-align: left;
    font-weight: normal;
  }
}

.hover {
  cursor: pointer;
}

.massa-dialog-subtitle {
  margin-top: -10px;
  margin-bottom: 12px;
}

.massa-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .ant-btn {
    min-width: 120px;
  }
}


.DetailItemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 36px;
  margin-right: 15px;

  img {
    height: 24px;
  }

  border-radius: 4px;
  transition: All 0.8s cubic-bezier(0.60, 0.00, 0.20, 1.00);
}

.DetailItemIconVideo {
  background: rgba(255, 120, 120, 0.10);
  border: 1px solid #FF7878;
  flex-shrink: 0;
}

.DetailItemIconArticle {
  background: rgba(245, 166, 35, 0.10);
  border: 1px solid #F5A623;
  flex-shrink: 0;
}

.DetailItemIconExample {
  background: rgba(0, 123, 255, 0.10);
  border: 1px solid #007BFF;
  flex-shrink: 0;
}

.DetailItemIconQuestion {
  background: rgba(123, 198, 0, 0.10);
  border: 1px solid #7BC600;
  flex-shrink: 0;
}

.massa-card{
  border-radius: 6px;
  background: white;
  border: 2px solid #F1F5FB;
  padding: 10px;
  .massa-card-title{
    padding: 5px 15px 5px 15px;
  }
  .massa-card-body{
    padding: 15px;
  }
}

.massa-shadow-item{
  transition: @SlowCubicBezier;
  &:hover{
    box-shadow: 0 6px 6px 0 rgba(7,5,11,0.06);
    transform:translateY(-6px);
    cursor: pointer;
  }
  &:active{
    transition: @CubicBezier;
    box-shadow: 0 0 6px 0 rgba(7,5,11,0.06);
  }
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  background: white;
  align-items: center;
  border: 2px solid #F1F5FB;
  padding: 15px;
  margin-bottom: 15px;
}

.react-pdf__Page__canvas{
  margin: 0 auto;
}
