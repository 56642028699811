.LoginPage {
  background: rgba(68, 68, 68, 0.05);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .BgImg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .LoginForm{
    width: 400px;
    //background-color: rgba(255, 255, 255, 0.051);
    padding: 40px;
    position: absolute;
    right: 100px;
    height: 400px;
    color: white;
    .ant-input{
      background: none;
      color: white;
    }
    .ant-input-password{
      background: none;
      color: white;
    }
    .anticon{
      color: white;
    }
  }
  .LoginFormBg{
    width: 402px;
    position: absolute;
    right: 100px;
    height: 402px;
  }

  .PageTitle{
    position: absolute;
    top: 20px;
  }

  .NoticeList {
    position: absolute;
    left: 100px;
    width: 400px;
    padding: 30px;
    background-color: rgb(17, 18, 110);
    height: 400px;
    color: white;
  }
}
