@import "./Theme";
.TabLayoutSidebar{
  background: white;
  width: @tab-layout-sidebar-width;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 0 25px fadeout(darken(@tab-layout-primary,15),80%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
  .TabLayoutSidebarItem{
    margin: 8px;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: @cubic-bezier;
    .TabLayoutSidebarItemIcon{
      font-size: 26px;
    }
    .TabLayoutSidebarItemTitle{
      font-weight: bold;
    }
    &:hover{
      background: fadeout(darken(@tab-layout-primary,35),95);
    }
  }
}
.TabLayoutSidebarPopover{
  width: 220px;
  .TabLayoutSidebarPopoverSubtitle{
    padding-left: 5px;
  }
  .TabLayoutSidebarLinkItem{
    cursor: pointer;
    transition: @cubic-bezier;
    padding: 5px;
    border-radius: 4px;
    &:hover{
      background: fadeout(darken(@tab-layout-primary,35),95);
    }
  }
}

