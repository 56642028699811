.smallMarkdown{
  line-height: 1.5em !important;
  p{
    margin: 5px 0 !important;
  }
  pre{
    margin: 5px 0 !important;
  }
  hr {
    margin: 5px 0 !important;
  }

  table, pre, dl, blockquote, q, ul, ol {
    margin: 5px 0 !important;
  }

  ul, ol {
    margin: 5px 0 !important;
    padding-left: 5px !important;
  }

  li {
    margin: 5px 5px !important;
  }

  li p {
    margin: 5px 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
    color: #000000 !important;
    margin: 5px 5px !important;
  }

  h1 {
    font-size: 20px !important;
    border-bottom: 0 !important;
  }

  h2 {
    font-size: 18px !important;
    border-bottom: 0 !important;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

}
